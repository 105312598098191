.mobile{
		width:100%;
		position: fixed;
	  bottom: 0;
	  left: 0;
	  right: 0;
	  background-color: #1F1F1F;
	  z-index: 2;

	}

	 .mobile .next{
	text-align:right;
	}

	.mobile .nextgrid, .mobile .prevgrid{
	width:37.5%;
	}

	.mobile .next, .mobile .previous, .mobile .play{
	padding: 15px;
	text-decoration:none;
	text-align:center;
	border-top: 1px solid #202020;

	} 

	.mobile .next:active, .mobile .previous:active, .mobile .play:active
	{
		background-color: #959067;
	}

	.mobile .next div{
		width: 0; 
		height: 0; 
		border-top: 8px solid transparent;
		border-bottom: 8px solid transparent;
		
		border-left: 8px solid white;
		float:right;
	}

	.mobile .previous div {
		width: 0; 
		height: 0; 
		border-top: 8px solid transparent;
		border-bottom: 8px solid transparent; 
		
		border-right:8px solid white; 
		float:left
	}

    .mobile .previous, .mobile .next, .mobile .play{background: none; color : #fff; display: flex; border: none}
	.mobile .next{border-left: 1px solid #343434; text-align:right; display: block;}
	.mobile .play{border-left:1px solid #343434; border-right: 1px solid #000; text-align:center; display: block;}
	.mobile .previous{border-right: 1px solid #000;text-align:left;}
	.mobile .rightarrow{margin-left:10px}
    .mobile .leftarrow{margin-right:10px}
    
    

    .mobile.nextprev button{
        width: 100%
    }

    .mobile.nextprev button.play{
        text-align: center;
    }

@media only screen and (max-width: 480px) {
	header{
		margin-bottom: 25px;
	}

		#previous, #next{
		display:none;
		}

        .placesnav{
            display:none;
        }

        .mobile-menu{
            display:none;
            clear:both;
            list-style:none;
            padding-left:0;
        }

        .m-gal-menu{
            display:block;
            clear: both;

        }
        .m-gal-menu li{
            text-align: right
        }

        #ground{
            background-color: #000;
        }
 /* nav, .banner{position: relative;}  */
        ul.m-gal-menu{
            position: absolute;
            z-index: 100;
    
    left:0;
    right:0;
    width: 100%;
    top:100px;
    background-color:#000;
    padding: 10px;
    box-sizing: border-box;
        }
}

/* @media only screen and (min-width: 480px) { */
@media only screen and (min-width: 768px) {
.mobilemenulink{display: none}
     .placesnav{
        display: none;
    }
    .leftcolumn, .rightcolumn{
        width:auto;
    }
    #slidearea{
        margin: 0 auto;
    }
    
    #photoframe{width: 75%; height:399px; position: relative;}
    
    .slide{
        width: auto;      
    }
    .main-image{
        /*height: 399px;*/
        width: 600px;
        /* background-color: red */

        
    }
    /*.main-image img{        
        position: absolute;
        bottom:0;
        left: 50%
    }*/ 
    .m-gal-menu{
            display:none;
        }
}

@media only screen and (max-width: 768px) {
	#photoframe .image {
		max-width: 100%;
		height: auto; 
		object-fit: contain;
	} 
	
	.nextslide, .prevslide{
		height:0;
	}
	#carousel{
		display:none;
	}
    
    .mobile-menu{
            display:none;
            clear:both;
            list-style:none;
            padding-left:0;
        }

        .m-gal-menu{
            display:block;
        }
        
        #photoframe{
            width:100%;
        }	
}

@media only screen and (min-width: 769px) {
    .mobile.nextprev{
		display:none;
    }   
    
     
    #slidearea{
        width:700px;
        margin: 0 auto;
    }
    #photoframe{
        width: 600px;
        margin: 0 auto;
		float:left
    }
    
     #carousel{
        width:90%;
    } 
    
    #gallery-wrap{
       width: 89%; 
    }
    .main-image img{
        width: auto;
    }

    .main-image img{        
        /* position: absolute; */
        bottom:0;
        /*left: 50%*/
    }
}

@media only screen and (min-width: 1024px) {

    .banner, .main-container {
        width: 1026px; /* 1140px - 10% for margins */
        margin: 0 auto;
    }
    #carousel{
        width:784px;
		/* width:90% */
    }
    
     .placesnav{
        display: block;
        height: 150px;
        position: relative;
        overflow-x: hidden;
        /*float:left;*/
    }
    
    /*.leftcolumn, .rightcolumn{
        width:20%;
		
    }   */
	
	.leftcolumn{
		width:21%
	}
	
	.rightcolumn{
		width:20%;
	}
     
    #slidearea{
        width:auto;
        margin: 0;
    }
    #photoframe{
        width: 600px;
        margin: 0 auto;
        display: flex;
        align-items: flex-end;
    } 
    
    .mobile-menu{
        display:none;
    }
}

@media only screen and (min-width: 1140px) {

/* ===============
    Maximal Width
   =============== */

    
    
/*     .leftcolumn, .rightcolumn{
       width:213px;
    }*/
    .leftcolumn{
        width:213px;
    }
    
    
}