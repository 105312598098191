@font-face {
    font-family: 'OstrichSansMedium';
    src: url('../fonts/ostrich-sans/ostrich-regular-webfont.eot');
    src: url('../fonts/ostrich-sans/ostrich-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/ostrich-sans/ostrich-regular-webfont.woff') format('woff'),
         url('../fonts/ostrich-sans/ostrich-regular-webfont.ttf') format('truetype'),
         url('../fonts/ostrich-sans/ostrich-regular-webfont.svg#OstrichSansMedium') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'OstrichSansBlack';
    src: url('../fonts/ostrich-sans/ostrich-black-webfont.eot');
    src: url('../fonts/ostrich-sans/ostrich-black-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/ostrich-sans/ostrich-black-webfont.woff') format('woff'),
         url('../fonts/ostrich-sans/ostrich-black-webfont.ttf') format('truetype'),
         url('../fonts/ostrich-sans/ostrich-black-webfont.svg#OstrichSansBlack') format('svg');
    font-weight: normal;
    font-style: normal;

}

@-webkit-keyframes fadein {
    0%   { opacity: 0; }
    100% { opacity: 1; }
}
@-moz-keyframes fadein {
    0%   { opacity: 0; }
    100% { opacity: 1; }
}
@-o-keyframes fadein {
    0%   { opacity: 0; }
    100% { opacity: 1; }
}
@keyframes fadein {
    0%   { opacity: 0; }
    100% { opacity: 1; }
}

@-webkit-keyframes slideup {
    0%      { opacity: .5; -webkit-transform: translateY(100px); }
    100% { opacity: 1; -webkit-transform: translateY(0%); }
}

@-webkit-keyframes slidedown {
    0%      { opacity: 1; -webkit-transform: translateY(0%); }
    100% { opacity: .5; -webkit-transform: translateY(100px) }
}
body{
    color: #fff;
}

.offscreen{
    /*text-indent: -1000px;*/
    margin-left: -1000px
}

.banner, .main-container {
        width: auto; /* 1140px - 10% for margins */
        margin: 0 15px;
    }

.banner h4{
    margin:  3px 0;
    font: 15px/26px 'OstrichSansBlack', Arial, sans-serif;
}

.banner ul{
    margin: 0px;
}

.banner li a{
    margin-left: 8px;
    text-decoration: none;
    font: 15px/26px 'OstrichSansBlack', Arial, sans-serif;
}

.banner li a:hover{text-decoration: underline}

.nameblock{
    border-bottom: 1px solid #9F9F9F;
}

header{
    margin-bottom: 50px;
    color:#fff;
}
header h1.ostrichmedium{
    font: 23px/26px 'OstrichSansMedium', Arial, sans-serif;
    color:#F9F1AC;
    margin-top: 2px;
    /*width:50%;*/
    padding-left: 1px;
}

header h1 {
    color:#fff;
    margin-bottom:0px;
}

header h1 span.ostrichblack{
    /*font: 18px/27px 'OstrichSansBlack', Arial, sans-serif;*/
    font: 40px/40px 'OstrichSansBlack', Arial, sans-serif; 
    padding-left: 1px;
}
header h1 span.ostrichmedium, h2.ostrichmedium{
    /*font: 18px/27px 'OstrichSansMedium', Arial, sans-serif;*/
    font: 44px/44px 'OstrichSansMedium', Arial, sans-serif; 
    padding-left: 1px;
}

h3.ostrichmedium{
    /*font: 15px/26px 'OstrichSansBlack', Arial, sans-serif;*/
    font: 20px/26px 'OstrichSansMedium', Arial, sans-serif;
    color:#F9F1AC;
    padding:0 0 0 2px;
    margin:0;
}

h2, li, a, p{
    color:#fff;
}

.text-center{
    text-align: center;
}

.left{
    display: block;
    overflow:hidden;
    float:left;
}
.right{
    display: block;
    overflow:hidden;
    float:right;
}

#slidearea{
    /*height:400px;*/
    overflow: hidden;
}

.currentslide{
    display: block;

}

.placesnav ul{
    margin: 0px;
    padding:0px;
}

.placesnav li:first-child{
    border-top: 1px dotted #8E8E8E
}

.placesnav li{
    padding-left: 2px;
    border-bottom: 1px dotted #8E8E8E
}

/*.placesnav li:last-child{
    border-bottom: 0px;
}*/



.placesnav ul a{
    font: 15px/26px 'OstrichSansBlack', Arial, sans-serif;
    
    text-decoration: none;
    padding-left: 1px;
}

.placesnav ul a:hover{
    color:#F9F1AC;
}

#previous, #next{
	display:block;	
}

#previous{
    text-align: right;
	
}

.nextslide{background: url(../images/icons/nextarrow.png) center center no-repeat;}
.prevslide{background: url(../images/icons/prevarrow.png) center center no-repeat;}

.nextslide, .prevslide{
    height: 50px;
    width:30px;
    border:none;
    padding: 0 10px
}
.nextslide:hover, .prevslide:hover{
    /*background-color:rgba(249, 241, 172, 0.39);*/
   /* border: 1px solid rgba(249, 241, 172)*/
}

.centerblock{
    display:block;
    margin-left: auto;
    margin-right: auto;
}

.displayblock{
    display:block;
}

.clear{
    clear: both;
}


.invisible{visibility:hidden;}

.overflow-hidden{
    overflow:hidden;
}
#photoframe{
    /*width:40%;*/
}
.photoinfo{
    width: 200px;
    color:#c0c0c0;
    border-left:1px solid #373737;
    padding-left: 20px;
}
.mrs{margin-right: 5px}
.mrm{margin-right: 20px;}
.mlm{margin-left: 20px;}
.mbl{margin-bottom: 40px;}
.mln{margin-left:0}
.ptm{padding-top:20px}

.size1of1{width:100%}
.size1of2{width:50%;}
.size1of3{width:33.33%;}
.size2of3{width:66.66%;}
.size1of4{width:25%;}
.size3of4{width:75%;}
.size1of5{width:20%;}
.size2of5{width:40%;}
.size3of5{width:60%;}
.size4of5{width:80%;}

.slide{
    /*width: 600px;*/
}

#ground{
    /*background-position:bottom;*/
    background: linear-gradient(to top, rgb(0, 0, 0) 37%, rgba(0, 0, 0, 0.65) 100%), center bottom no-repeat;
}

/* reflection css */
body{background-color:#000}
b{color:white;}
.main-image{
	/*width:500px;*/
	margin:0px auto 0px auto;
    overflow:hidden;
    /*transition: opacity 300ms linear;*/    
}

.main__image--fadein{
    -webkit-animation: fadein 2s infinite; /* Safari 4+ */
    -moz-animation:    fadein 2s infinite; /* Fx 5+ */
    -o-animation:      fadein 2s infinite; /* Opera 12+ */
    animation:         fadein 2s infinite; /* IE 10+, Fx 29+ */
}

#image-reflection{
    position: relative;
	/*width:500px;*/
	margin:0px auto;
        overflow: hidden;
        height: 220px;
	/*background-image: -webkit-linear-gradient(top, #000, #2A2A2B);
	background-image:    -moz-linear-gradient(top, #000, #2A2A2B);
	background-image:     -ms-linear-gradient(top, #000, #2A2A2B);
	background-image:      -o-linear-gradient(top, #000, #2A2A2B);*/
}

/* renamed to floors instead of commenting out the code*/
#floors{
	display:block;
        position: relative;
	margin:0px; 
	padding:0px;
	
	
        
        
        background: rgb(24,24,24); /* Old browsers */
        /* IE9 SVG, needs conditional override of 'filter' to 'none' */
        background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzE4MTgxOCIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjcyJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgPC9saW5lYXJHcmFkaWVudD4KICA8cmVjdCB4PSIwIiB5PSIwIiB3aWR0aD0iMSIgaGVpZ2h0PSIxIiBmaWxsPSJ1cmwoI2dyYWQtdWNnZy1nZW5lcmF0ZWQpIiAvPgo8L3N2Zz4=);
        background: -moz-linear-gradient(top,  rgba(24,24,24,1) 0%, rgba(0,0,0,1) 72%); /* FF3.6+ */
        background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(24,24,24,1)), color-stop(72%,rgba(0,0,0,1))); /* Chrome,Safari4+ */
        background: -webkit-linear-gradient(top,  rgba(24,24,24,1) 0%,rgba(0,0,0,1) 72%); /* Chrome10+,Safari5.1+ */
        background: -o-linear-gradient(top,  rgba(24,24,24,1) 0%,rgba(0,0,0,1) 72%); /* Opera 11.10+ */
        background: -ms-linear-gradient(top,  rgba(24,24,24,1) 0%,rgba(0,0,0,1) 72%); /* IE10+ */
        background: linear-gradient(to bottom,  rgba(24,24,24,1) 0%,rgba(0,0,0,1) 72%); /* W3C */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#181818', endColorstr='#000000',GradientType=0 ); /* IE6-8 */

	}
        
#floor{
	display:block;
        position: relative;
	margin:0px; 
	padding:0px;         
        background-color: #3A3A3A;
}

#floor img{display:block; clear:both;}

.flip-vertical {
    -moz-transform: scaleY(-1);
    -webkit-transform: scaleY(-1);
    -o-transform: scaleY(-1);
    -ms-transform:scaleY(-1);
    transform: scaleY(-1);
    /*filter: flipv; IE*/
}

.gradient-block{
/*position:absolute;
top:0px;
border:solid 1px white;*/
/*	background-image: -webkit-linear-gradient(bottom, #000 50%, rgba(0, 0, 0, 0.75));
	background-image:    -moz-linear-gradient(center bottom , #000 50%, rgba(0, 0, 0, 0.75));
	background-image:     -ms-linear-gradient(bottom, #000, #2A2A2B);
	background-image:      -o-linear-gradient(bottom, #000, #2A2A2B);
        background-image:     linear-gradient(to top, #000, rgba(0, 0, 0, 0.75));*/
	
       
/*        background: -moz-linear-gradient(bottom, rgba(42,42,43,0.65) 0%, rgba(0,0,0,0) 100%); 
        
        width:100%;
	height:386px;*/
        
        /*filter:  progid:DXImageTransform.Microsoft.gradient(startColorStr='#444444', EndColorStr='#999999'); /* IE6,IE7 */
/*-ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorStr='#444444', EndColorStr='#999999')"; /* IE8 */
}

.mf{
    height: 100px;
}

.thumb{
    opacity: 0.4;
    /* min-width: 150px; */
    transition: opacity 300ms linear;
}

.thumb.current{
    opacity: 1;
}

.thumb:hover {
    /*height:100px;
    width:200px;
    border:1px solid #ffffff;*/
    cursor: pointer;
}

/**/
#carousel{
    /*display: block;
    height: 200px;*/
    margin: 0 auto;
    /*position: absolute;
    top: 600px;*/
    width: 75%;
    padding-top: 25px;
    
}
#carousel ul{
   padding: 0px; 
}

a{
    outline: none;
}

#autoslide
{
    color: #fff;   
    border: none;
    background: none;
    /* width: 32px; */
    clear: both;
}

#autoslide, #slidearea a{
	outline:none;
    text-decoration:none;
    margin-left: auto;
    margin-right: auto;
}

/* #gallery-wrap{margin: 0 auto; overflow: hidden; width: 714px; position: relative;}
#gallery{position: relative; left: 0; top: 0; overflow:hidden;}
#gallery li{float: left; margin: 0 20px 15px 0;}
#gallery li a img{border: 1px solid #3D3D3D; padding:4px}
*/

#gallery li{border:none}
#gallery li div img{
    border: 1px solid #3D3D3D; 
    padding:4px;     
    margin-left: auto;
    margin-right: auto;
}

#gallery{
    display: flex;
    position: absolute;
    left:0px;
    transition: transform 300ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

#gallery li{

}
#gallery-controls{margin: 0 auto; width: 732px;}

#gallery-prev, #gallery-next{
    margin-top: 50px;
    height: 40px;
    float: left; 
    overflow: hidden;     
    border: none;
    background: none;
    width: 32px;
}
#gallery-prev{margin-right: 10px}
#gallery-next{margin-left: 10px} 

.image{
    position: relative;
    bottom: 0px;
}

#gallery .current img{
    border-color:#F9F1AC;
}

.placesnav{
        display: none
    }
    
footer{
    position: fixed;
    bottom: 0;
    padding-bottom: 3px;
    text-align: center;    
    width:100%;
    background-color: #000;    
}

footer p {
    font-size:0.7em;
    color: #4e4e4e;
    /*position:absolute;
    bottom:0;*/
    width:100%;
    clear:both;
}

.hidden{
    display:none;
}